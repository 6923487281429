import React, { useState } from "react";
import Recaptcha from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { useRegister } from "../../api/auth";
import { Button, ButtonType, ButtonVariant } from "../../components/Button/Button";
import { Image } from "../../components/Image/Image";
import { Input } from "../../components/Input/Input";
import { SlideShow } from "../../components/SlideShow/SlideShow";
import { useMatchScreen } from "../../hooks/screen/useMatchScreen";
import { useUrlQuery } from "../../hooks/url/useUrlQuery";
import { showNotification } from "../../redux/reducers/notificationReducer";
import { NotificationSeverity } from "../../redux/types/notification";

import styles from "./RegisterPage.module.scss";

interface RegisterInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation?: string;
  captchaToken?: string | null;
}

export const RegisterPage: React.FC = () => {
  const registerMutation = useRegister();
  const { register, handleSubmit } = useForm<RegisterInput>();
  const history = useHistory();
  const query = useUrlQuery();
  const [isInvite] = useState(!!query.get("invite"));
  const dispatch = useDispatch();
  const isScreenMatch = useMatchScreen(950);
  const [isVerified, setVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const onSubmit: SubmitHandler<RegisterInput> = async (data) => {
    if (!isVerified) {
      return dispatch(showNotification({ message: "Please verify that you are a human.", severity: NotificationSeverity.ERROR }));
    }
    if (data.password !== data.passwordConfirmation) {
      return dispatch(showNotification({ message: "Passwords don't match", severity: NotificationSeverity.ERROR }));
    }
    data.captchaToken = captchaToken;
    registerMutation.mutate(data);
  };

  const onRecaptchaVerify = (response: string | null) => {
    setCaptchaToken(response);
    if(response) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  };

  return (
    <div className={styles.RegisterPage}>
      <div className={styles.firstHalf}>
        <div className={styles.firstHalfContent}>
          <div className={styles.column}>
            <h1>{isInvite ? "Got an invitation?" : "New here?"}</h1>
            <div className={styles.subtitle}>
              {isInvite
                ? "Great to see you here! Fill remaining fields and enjoy Ecomtrack!"
                : "Fill your username and password below and enjoy Ecomtrack!"}
            </div>
          </div>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.name}>
              <Input
                icon="user"
                label="First name*"
                placeholder="First name"
                required
                {...register("firstName", { required: "This field is required" })}
              />
              <Input
                icon="user"
                label="Last name*"
                placeholder="Last name"
                required
                {...register("lastName", { required: "This field is required" })}
              />
            </div>
            <Input
              icon="email"
              label="Email address*"
              placeholder="Email"
              required
              {...register("email", {
                required: "This field is required",
              })}
              type="email"
            />
            <Input
              icon="lock"
              label="Password*"
              type="password"
              placeholder="Password"
              required
              {...register("password", { required: "This field is required" })}
            />
            <Input
              icon="lock"
              label="Password again*"
              type="password"
              placeholder="Password"
              required
              {...register("passwordConfirmation", { required: "This field is required" })}
            />
            <Recaptcha
                sitekey="6LehGt4fAAAAAKkIHu0RcF4YScFigj2lnLfZpYlU"
                onChange={onRecaptchaVerify}
            />
            <div className={styles.button}>
              <Button
                variant={ButtonVariant.PRIMARY}
                type={ButtonType.SUBMIT}
                fullWidth
                loading={registerMutation.isLoading}>
                Sign up
              </Button>
            </div>
          </form>
          <div className={styles.row}>
            <p>
              Do you have an account already? Proceed to{" "}
              <Link to={{ pathname: "/login", search: history.location.search }}>Log-in.</Link>
            </p>
          </div>
        </div>
        <div className={styles.logo}>
          <Image src="logo.svg" alt="ecomtrack logo" />
        </div>
      </div>
      {!isScreenMatch && (
        <div className={styles.secondHalf}>
          <SlideShow />
        </div>
      )}
    </div>
  );
};
