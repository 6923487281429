import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { UseMutationResult } from "react-query/types/react/types";
import { useHistory } from "react-router";
import { ErrorMessage } from "@hookform/error-message";

import { RequestPasswordResetTokenRequestDTO } from "../../api/auth";
import { Button, ButtonType, ButtonVariant } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";

import styles from "./RecoveryLinkForm.module.scss";

interface RecoveryLinkFormProps {
  recoverPasswordMutation: UseMutationResult<any, unknown, RequestPasswordResetTokenRequestDTO, unknown>;
}

export const RecoveryLinkForm: React.FC<RecoveryLinkFormProps> = ({ recoverPasswordMutation }) => {
  const history = useHistory();

  const onSubmit: SubmitHandler<RequestPasswordResetTokenRequestDTO> = async ({ email }) => {
    recoverPasswordMutation.mutate({ email });
  };
  const handleBack = () => {
    history.goBack();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestPasswordResetTokenRequestDTO>();

  return (
    <>
      <div className={styles.column}>
        <h1>Reset password</h1>
        <p className={styles.subtitle}>Write us your email and we will send you an activation link in a moment.</p>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          icon="email"
          label="Email address*"
          placeholder="email"
          type="email"
          {...register("email", {
            required: "This field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          })}
          required
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <span role="alert" className="errorMessage" style={{ marginBottom: "-30px", marginTop: "0px" }}>
              {message}
            </span>
          )}
        />
        <div className={styles.buttons}>
          <Button variant={ButtonVariant.PRIMARY} type={ButtonType.SUBMIT} loading={recoverPasswordMutation.isLoading}>
            Send
          </Button>
          <Button variant={ButtonVariant.SECONDARY} type={ButtonType.BUTTON} onClick={handleBack}>
            Back
          </Button>
        </div>
      </form>
    </>
  );
};
